import React from "react";

import logo from "../images/logo.svg";

const LogoPosition = () => {
  return (
    <a href="/">
      <img src={logo} alt="logo" />
    </a>
  );
};

export default LogoPosition;
