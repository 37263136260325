import React, { useEffect, useState } from "react";
import Button from "../../elements/Button";
import team from "../../images/team.jpg";
import "../../style/gameMaster/MatchLobby.scss";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useTransition, animated } from "react-spring";
import { useNavigate, Link } from "react-router-dom";
import { API } from "../../services/base";
import axios from "../../services/https";

import { useDispatch } from "react-redux";
import {
  startTime,
  member,
  joinedMembers,
  kickedTeam,
} from "../../redux/actions/app.js";
import { useSelector } from "react-redux";

import Echo from "laravel-echo";
import moment from "moment";

const MyMatchLobby = () => {
  const [expanded, setExpanded] = useState("panel0");
  const [time, setTime] = useState(localStorage.getItem("time"));

  const [game, setGame] = useState(1);
  const [otherTeams, setOtherTeams] = useState([]);
  let [checkTime, setCheckTime] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Popups
  const [open, setOpen] = useState(false);
  const [startNew, setStartNew] = useState(false);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const matchTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsNew = useTransition(startNew, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const newTransitions = useTransition(startNew, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  // Subscribe
  const [roomChannel, setRoomChannel] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setRoomChannel(res.data.data.roomScreenChannel);
      })
      .catch((err) => {
        console.log(err);
        switch (err.response.status) {
          case 403:
            navigate("/");
          default:
            break;
        }
      }, []);
  }, []);

  useEffect(() => {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "2UhByxffTQ7XjcYWgfHbumDj",
      cluster: "mt1",
      authEndpoint: "https://arena-api.qwertify.io/broadcasting/auth",
      wsHost: "arena-api.qwertify.io",
      auth: {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + `${token}`,
        },
      },
      wsPort: 6001,
      wssPort: 6001,
      disableStats: true,
      encrypted: true,
      enabledTransports: ["ws", "wss"],
      transports: ["websocket"],
    });

    axios
      .get(`${API}/game/${app.lobby.id}`)
      .then((res) => {
        window.Echo.channel(res.data.data.socketChannel).listen(
          ".game.team-joined",
          (e) => {
            console.log("Team joined");
            dispatch(joinedMembers(e.team));
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // End subscribe

  const handleGoBack = () => {
    setOpen(!open);
  };

  let [kickTeamId, setKickTeamId] = useState("");

  const handleKick = (id) => {
    const kickId = id;

    setOpen(!open);
    setKickTeamId(kickId);
  };

  const handleKickTeam = () => {
    const data = {
      game: app.lobby.id,
      team: kickTeamId,
    };
    axios
      .post(`${API}/game/${app.lobby.id}/kick/${kickTeamId}`, data)
      .then((res) => {
        // window.location.reload(false);
        setOpen(!open);
        dispatch(kickedTeam(kickTeamId));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateNew = () => {
    localStorage.removeItem("persist:root");
    localStorage.removeItem("time");
    navigate(`/check/${token}`);
  };

  const handelSoloMatch = () => {
    const newTime = moment().format("HH:ss");

    console.log(newTime.toString());

    const data = {
      teamId: app.team.id,
      startTime: newTime.toString(),
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        axios
          .post(`${API}/game/${res.data.data.id}/start`)
          .then((res) => {
            navigate("/solo-platform");
            localStorage.setItem("solo", res.data.data.id);

            const found = res.data.data.teams.find((obj) => {
              return obj.id === app.team.id;
            });

            dispatch(startTime(found.gameStartedAt));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStartMatch = () => {
    const data = {
      game: app.lobby.id,
    };
    axios
      .post(`${API}/game/${app.lobby.id}/start`, data)
      .then((res) => {
        navigate("/my-platform");
        const found = res.data.data.teams.find((obj) => {
          return obj.id === app.team.id;
        });

        dispatch(startTime(found.gameStartedAt));
        localStorage.setItem("room", app.lobby.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${API}/game/${app.lobby.id}`)
      .then((res) => {
        setOtherTeams(res.data.data.teams);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  var myzone = moment.tz.guess();
  var localTime = moment
    .utc(`${time}`, "HH:mm:ss")
    .tz(myzone)
    .format("HH:mm:ss");

  var extraTime = moment(localTime, "HH:mm:ss")
    .add(5, "seconds")
    .format("HH:mm:ss");

  const currentTime = moment().format("HH:mm:ss");

  useEffect(() => {
    const interval = setInterval(() => {
      var myzone = moment.tz.guess();
      var start = moment(`${time}`, "HH:mm:ss");
      checkTime = moment();
      let diff = start.diff(checkTime);

      checkTime = moment.utc(diff).format("mm:ss");
      setCheckTime(moment.utc(diff).format("mm:ss"));
    }, 600);

    if (currentTime === extraTime) {
      const data = {
        game: app.lobby.id,
        teamId: app.team.id,
      };
      axios
        .post(`${API}/game/${app.lobby.id}/leave`, data)
        .then((res) => {
          setStartNew(!startNew);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => clearInterval(interval);
  }, [checkTime, extraTime]);

  return (
    <div className="matchLobbyScreen">
      {/* Kick team out */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {matchTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                <h2 className="leaveMatchTitle">
                  Are you sure you want to kick team from lobby?
                </h2>
                <Button title="Proceed" onClick={handleKickTeam} />
                <Link className="leaveMatch" onClick={handleGoBack}>
                  GO BACK TO LOBBY
                </Link>
              </div>
            </animated.div>
          )
      )}
      {/* End kick team out */}

      {/* Start new game */}
      {transitionsNew(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setStartNew(false)}
            ></animated.div>
          )
      )}

      {newTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                <h2 className="leaveMatchTitle">Some text is going here!</h2>
                <Button title="START SOLO GAME" onClick={handelSoloMatch} />
                <button className="btnLeaveMatch" onClick={handleCreateNew}>
                  CREATE NEW TEAM
                </button>
              </div>
            </animated.div>
          )
      )}
      {/* End start new game */}

      <div className="matchStart">
        <div className="startTime">
          {(() => {
            if (currentTime < localTime) {
              return (
                <div>
                  Match starts in: <br />
                  <span>{checkTime}</span>
                </div>
              );
            } else if (currentTime <= extraTime) {
              return (
                <div>
                  Last chance to join: <br />
                  <span>05:00</span>
                </div>
              );
            } else {
              return (
                <div>
                  Not available <br />
                  <span>00:00</span>
                </div>
              );
            }
          })()}
        </div>
        {(() => {
          if (currentTime < localTime) {
            return (
              <Button
                title="START!"
                disabled={true}
                onClick={handleStartMatch}
                className="myClass"
              />
            );
          } else if (currentTime <= extraTime) {
            return <Button title="START!" onClick={handleStartMatch} />;
          } else {
            return (
              <Button
                title="START!"
                disabled={true}
                onClick={handleStartMatch}
                className="myClass"
              />
            );
          }
        })()}
      </div>

      <div className="lobby">
        <h2>Your lobby</h2>

        <div className="myTeam">
          <h2>Your team</h2>
          <Accordion
            expanded={expanded === "panel0"}
            onChange={handleChange("panel0")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="globalDropdown"
            >
              <Typography component={"span"} className="teamDropdown">
                <span>Team name</span> {app.team.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              <Typography component={"span"} className="teamImageAccordion">
                <div className="teamImage">
                  <div className="image">
                    <img
                      src={`https://arena-api.qwertify.io${app.team.image}`}
                      alt="team"
                    />
                  </div>
                </div>
                <div className="teamInfo">
                  <div className="roomFranchize">
                    <span>Escpare room franchize</span>
                    <h5>{app.team.companyName}</h5>
                  </div>

                  <div className="location">
                    <span>Location</span>
                    {!app.team.roomAddress ? (
                      <h5>-</h5>
                    ) : (
                      <h5>{app.team.roomAddress}</h5>
                    )}
                  </div>

                  <div className="roomName">
                    <span>Escape room name</span>
                    <h5>{app.team.roomName}</h5>
                  </div>

                  <div className="difficultyLevel">
                    <span>Difficulty level</span>
                    <h5>{app.team.roomDifficultyLevel}</h5>
                  </div>

                  <div className="solvingFrame">
                    <span>Time frame for solving</span>
                    <h5>around {app.team.roomDuration} minutes</h5>
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        {app.joined.length > 0 ? (
          <div className="otherTeams">
            <h2>Other teams</h2>
            <div className="teamCards">
              {app.joined.map((item, index) => {
                return (
                  <div className="card" key={item.id}>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="globalDropdown"
                      >
                        <Typography component={"span"} className="teamDropdown">
                          <span>Team name</span> {item.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="accordionDetails">
                        <Typography
                          component={"span"}
                          className="teamImageAccordion"
                        >
                          <div className="teamImage">
                            <div className="image">
                              <img
                                src={`https://arena-api.qwertify.io${item.image}`}
                                alt="team"
                              />
                            </div>
                          </div>
                          <div className="teamInfo">
                            <div className="roomFranchize">
                              <span>Escpare room franchize</span>
                              <h5>{item.companyName}</h5>
                            </div>

                            <div className="location">
                              <span>Location</span>
                              {!item.roomAddress ? (
                                <h5>-</h5>
                              ) : (
                                <h5>{item.roomAddress}</h5>
                              )}
                            </div>

                            <div className="roomName">
                              <span>Escape room name</span>
                              <h5>{item.roomName}</h5>
                            </div>

                            <div className="difficultyLevel">
                              <span>Difficulty level</span>
                              <h5>{item.roomDifficultyLevel}</h5>
                            </div>

                            <div className="solvingFrame">
                              <span>Time frame for solving</span>
                              <h5>arond {item.roomDuration} minutes</h5>
                            </div>
                          </div>
                          <div className="kickTeam">
                            <button onClick={(e) => handleKick(item.id)}>
                              KICK TEAM OUT
                            </button>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MyMatchLobby;
