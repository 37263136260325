import React, { useState, useEffect } from "react";
import "../../style/gameMaster/WinningDisplay.scss";

import logo from "../../images/arena.svg";
import team from "../../images/team.jpg";

import Echo from "laravel-echo";

import winner from "../../images/winner-photo.png";

import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const WinningDisplay = () => {
  const [teams, setTeams] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [time, setTime] = useState(localStorage.getItem("time"));

  const [roomChannel, setRoomChannel] = useState("");

  //   Redux
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();

  // Show teams
  const [showTeams, setShowTeams] = useState([]);
  const [bestTimes, setBestTimes] = useState([]);

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setRoomChannel(res.data.data.roomScreenChannel);
      })
      .catch();
  }, []);

  useEffect(() => {
    axios
      .get(`${API}/game/${app.allFinished.gameId}/winner`)
      .then((res) => {
        setShowTeams(res.data.data.teams);
        setBestTimes(res.data.data.bestTimes);
      })
      .catch();
  }, []);

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "2UhByxffTQ7XjcYWgfHbumDj",
    cluster: "mt1",
    authEndpoint: "https://arena-api.qwertify.io/broadcasting/auth",
    wsHost: "arena-api.qwertify.io",
    auth: {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + `${token}`,
      },
    },
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    encrypted: true,
    enabledTransports: ["ws", "wss"],
    transports: ["websocket"],
  });
  // channel.private-game-1 = roomScreenChannel

  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const channel = window.Echo.channel(`${roomChannel}`).listen(
      ".reset",
      (e) => {
        navigate(`/in-game/${token}`);
        localStorage.removeItem("persist:root");
        window.location.reload(false);
        console.log("RESET ROOM WINNING DISPLAY");
      }
    );

    channel.on("reconnecting", () => {
      setIsConnected(false);
    });

    channel.on("reconnected", () => {
      setIsConnected(true);
    });

    return () => {
      channel.unsubscribe();
    };
  });

  return (
    <div className="winningDisplay">
      {
        showTeams.map((item, index) => (
          <div className="winner">
            <div className="winnerLogo">
              <img src={logo} alt="logo" />
              <h5>WINNERS!</h5>
            </div>
            <div className="winnerNameId">
              <div className="winnerId">1</div>
              <div className="winnerName">{item.name}</div>
            </div>
            <div className="winnerInfo">
              <img src={team} alt="team" />
              <div className="statistics">
                <span>{Math.round(item.points)} POINTS</span>
                <span>{item.hintCount} HINTS</span>
                <span>
                  {item.timeLeft.slice(0, 2)}m {item.timeLeft.slice(3, 5)}s LEFT
                </span>
              </div>
            </div>

            <div className="dot"></div>
            <div className="dot1"></div>
            <div className="dot2"></div>
            <div className="dot3"></div>
          </div>
        ))[0]
      }

      {showTeams.length === 1 ? (
        <div className="otherTeamsScore">
          <div className="anim primary">
            {bestTimes.map((item, index) => {
              return (
                <div className="topPlayerCard" key={item.id}>
                  <div className="teamRate">{index + 1}</div>
                  <div className="teamName">{item.name}</div>
                  <div className="teamScore">
                    <span>{item.points} POINTS</span> &nbsp; / 4 HINTS /{" "}
                    {item.timeLeft.slice(0, 2)}m {item.timeLeft.slice(3, 5)}s
                    LEFT
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : showTeams.length < 9 ? (
        <div className="otherTeamsScore">
          <div className="anim">
            {showTeams.slice(1).map((item, index) => {
              return (
                <div className="topPlayerCard" key={item.id}>
                  <div className="teamRate">{index + 1}</div>
                  <div className="teamName">{item.name}</div>
                  <div className="teamScore">
                    <span> {Math.round(`${item.points}`)}&nbsp; POINTS</span>{" "}
                    &nbsp; / 4 HINTS / {item.timeLeft.slice(0, 2)}m{" "}
                    {item.timeLeft.slice(3, 5)}s LEFT
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="otherTeamsScore">
          <div className="anim primary">
            {showTeams.slice(1).map((item, index) => {
              return (
                <div className="topPlayerCard" key={item.id}>
                  <div className="teamRate">{index + 1}</div>
                  <div className="teamName">{item.name}</div>
                  <div className="teamScore">
                    <span>{item.points} POINTS</span> &nbsp; / 4 HINTS /{" "}
                    {item.timeLeft.slice(0, 2)}m {item.timeLeft.slice(3, 5)}s
                    LEFT
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default WinningDisplay;
