import * as types from "../types/actionTypes";

// NEW
export const getTeams = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_TEAMS,
      payload: data,
    });
  };
};

export const joinedMembers = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.MEMBERS_JOINED,
      payload: data,
    });
  };
};

export const kickedTeam = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.KICKED_TEAM,
      payload: data,
    });
  };
};

export const saveMyMatchLobby = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_MYMATCH_LOBBY,
      payload: data,
    });
  };
};

export const finish = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.FINISHED_GAME,
      payload: data,
    });
  };
};

export const startedGame = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.STARTED_GAME,
      payload: data,
    });
  };
};

export const getTeamsNewValue = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.TEAM_NEW_VALUE,
      payload: data,
    });
  };
};

export const startTime = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.START_TIME,
      payload: data,
    });
  };
};

export const showGameId = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_GAME_ID,
      payload: data,
    });
  };
};

export const completedSteps = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.COMPLETED_STEPS,
      payload: data,
    });
  };
};

export const givenHint = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.GIVEN_HINT,
      payload: data,
    });
  };
};

export const roomReset = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_ROOM_RESET,
      payload: data,
    });
  };
};

// END NEW

export const saveTeam = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_TEAM,
      payload: data,
    });
  };
};

export const test = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.TEST,
      payload: data,
    });
  };
};

export const progressid = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.PROGRESSID,
      payload: data,
    });
  };
};

export const progresscount = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.PROGRESSCOUNT,
      payload: data,
    });
  };
};

export const saveRoom = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_ROOM,
      payload: data,
    });
  };
};

export const saveGame = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_GAME,
      payload: data,
    });
  };
};

export const updateGame = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_GAME,
      payload: data,
    });
  };
};

export const saveHint = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.GIVE_HINT,
      payload: data,
    });
  };
};

export const availablesTeams = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.AVAILABLE_MATCHES_TEAMS,
      payload: data,
    });
  };
};

export const updateTeams = (id, progress) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_TEAMS,
      payload: { id, progress },
    });
  };
};

export const member = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.JOINED_MEMBER,
      payload: data,
    });
  };
};

export const updateMembers = (id, progress) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_MEMBERS,
      payload: { id, progress },
    });
  };
};

export const members = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.ARRAY_MEMBERS,
      payload: data,
    });
  };
};

export const teamarray = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.TEAMS_ARRAY,
      payload: data,
    });
  };
};

export const getHints = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_HINTS,
      payload: data,
    });
  };
};
