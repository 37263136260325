import React, { useState, useEffect } from "react";
import Echo from "laravel-echo";
import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate } from "react-router-dom";

// Style
import "../../style/gameMaster/InGame.scss";

// Images

import moment from "moment";

// Redux
import { connect, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getTeams,
  member,
  startedGame,
  showGameId,
  completedSteps,
  givenHint,
  roomReset,
  finish,
  getTeamsNewValue,
} from "../../redux/actions/app.js";

import InactiveSvg from "../gameMaster/InactiveSvg.js";
import ActiveSvg from "../gameMaster/ActiveSvg.js";

const InGame = () => {
  const [roomChannel, setRoomChannel] = useState("");
  const navigate = useNavigate();

  //   All about token
  const [cards, setCards] = useState(localStorage.getItem("token"));
  // const [otherTeam, setOtherTeam] = useState(
  //   JSON.parse(localStorage.getItem("persons"))
  // );
  const [soloCheck, setSoloCheck] = useState(localStorage.getItem("soloTrue"));
  let [token, setToken] = useState([]);
  token = window.location.href.split("/").pop();
  const [socketCh, setSocketCh] = useState("");
  const [gameId, setGameId] = useState("");

  const [allTeams, setAllTeams] = useState([]);
  const [otherTeams, setOtherTeams] = useState(null);
  const [bestTimes, setBestTimes] = useState([]);
  const [savedTeamsOnRefresh, setSavedTeamsOnRefresh] = useState([]);
  // let [currentTime, setCurrentTime] = useState("");

  let [checkTime, setCheckTime] = useState("");

  //   Redux
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  // Btn animate

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 2000);
  };

  // End timer

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setRoomChannel(res.data.data.roomScreenChannel);
      })
      .catch((err) => {
        console.log(err);
        switch (err.response.status) {
          case 403:
            navigate("/");
          default:
            break;
        }
      }, []);

    localStorage.setItem("token", token);
  }, []);

  useEffect(() => {
    setAllTeams(app.get);
  }, [app]);

  useEffect(() => {
    if (app.gameid != null) {
      axios
        .get(`${API}/game/${app.gameid}/winner`)
        .then((res) => {
          setBestTimes(res.data.data.bestTimes);
        })
        .catch();
    }
  }, [app.gameid]);

  //   Socket

  window.Pusher = require("pusher-js");

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "2UhByxffTQ7XjcYWgfHbumDj",
    cluster: "mt1",
    authEndpoint: "https://arena-api.qwertify.io/broadcasting/auth",
    wsHost: "arena-api.qwertify.io",
    auth: {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + `${cards}`,
      },
    },
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    encrypted: true,
    enabledTransports: ["ws", "wss"],
    transports: ["websocket"],
  });

  // channel.private-game-1 = roomScreenChannel
  const [isConnected, setIsConnected] = useState(true);
  function connect() {
    const channel = window.Echo.channel(`${roomChannel}`)
      .listen(".redraw", (e) => {
        console.log("subscribed", e);

        localStorage.setItem("roomChannel", roomChannel);
        dispatch(showGameId(e.gameId));
        setSocketCh(e.socketChannel);
        setGameId(e.gameId);

        axios
          .get(`${API}/game/${e.gameId}`)
          .then((res) => {
            const gameid = e.gameId;
            setAllTeams(res.data.data.teams);
            dispatch(getTeams(res.data.data.teams));
            // setAllTeams(res.data.data.teams);

            window.Echo.channel(res.data.data.socketChannel)
              .listen(".game.game-started", (e) => {
                dispatch(startedGame(e));
              })
              .listen(".game.hint-given", (e) => {
                dispatch(givenHint(e));
                setShake(!shake);
              })
              .listen(".game.step-changed", (e) => {
                dispatch(completedSteps(e));
              })
              .listen(".game.team-joined", (e) => {
                dispatch(member(e.team));
              })
              .listen(".game.team-finished", (e) => {})
              .listen(".game.game-finished", (e) => {
                dispatch(finish(e));
                navigate("/winning-display");
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .listen(".reset", (e) => {
        navigate(`/in-game/${cards}`);
        localStorage.removeItem("persist:root");
        window.location.reload(false);
        console.log("RESET ROOM IN GAME");
      })
      .error((error) => {
        console.error(`Error subscribing to channel: ${error}`);
        // Reconnect after 5 seconds
        setTimeout(() => {
          connect();
        }, 2000);
      });

    channel.onmessage = function (e) {
      console.log("Message:", e.data);
    };

    channel.onclose = function (e) {
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second.",
        e.reason
      );
      setTimeout(function () {
        connect();
      }, 1000);
    };

    channel.on("reconnecting", () => {
      setIsConnected(false);
    });

    channel.on("reconnected", () => {
      setIsConnected(true);
    });

    return () => {
      channel.unsubscribe();
    };
  }
  connect();

  // useEffect(() => {
  //   if (app.gameid) {
  //     axios
  //       .get(`${API}/game/${app.gameid}`)
  //       .then((res) => {
  //         dispatch(getTeamsNewValue(res.data.data.teams));
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, []);

  // TIMER

  const [endDate, setEndDate] = useState(null);

  var end = endDate;

  //  TIMER
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setAllTeams((previousState) =>
  //       previousState.map((team, index) => {
  //         if (team?.gameStartedAt) {
  //           // console.log("ima start date");
  //           let tempDiff = moment(
  //             team.gameStartedAt,
  //             "YYYY-MM-DD HH:mm:ss"
  //           ).diff(moment());
  //           return {
  //             ...team,
  //             currentTime: moment.utc(tempDiff).format("mm:ss"),
  //           };
  //         }
  //         // console.log("nema start date");
  //         return team;
  //       })
  //     );
  //   }, 600);
  //   return () => clearInterval(interval);
  // }, [allTeams]);
  // END TIMER

  const trueObjects = app.get.filter((obj) => obj.gameStartedAt);

  return (
    <div className="inGame">
      <div className="inGameContent">
        <div className="topBoard">
          <ul>
            <li>starting number</li>
            <li>team name</li>
            <li>company & room</li>
            <li>city</li>
            <li>hints given</li>
            <li>time left</li>
            <li>points</li>
          </ul>
        </div>

        <div className="teamsInGame">
          {!allTeams
            ? ""
            : trueObjects.map((item, index) => {
                return (
                  <div className="specificTeam" key={item.id} id={item.id}>
                    <div className="starginPlayer">
                      <span>{index + 1}</span>
                      <img
                        src={`https://arena-api.qwertify.io${item.image}`}
                        alt={`https://arena-api.qwertify.io${item.image}`}
                      />
                    </div>
                    <div className="wraperProgress">
                      <div className="teamName">{item.name}</div>
                      <div className="companyAndRoom">
                        <h5>{item.companyName}</h5>
                        <h6>{item.roomName}</h6>
                      </div>
                      <div className="teamCity">
                        <h6> {!item.roomLocation ? "-" : item.roomLocation}</h6>
                      </div>
                      {(() => {
                        if (item.hintCount === 1) {
                          return (
                            <div className="givenHints">
                              <ActiveSvg className={shake ? `shake` : null} />
                              <InactiveSvg />
                              <InactiveSvg />
                              <InactiveSvg />
                              <InactiveSvg />
                            </div>
                          );
                        } else if (item.hintCount === 2) {
                          return (
                            <div className="givenHints">
                              <ActiveSvg />
                              <ActiveSvg className={shake ? `shake` : null} />
                              <InactiveSvg />
                              <InactiveSvg />
                              <InactiveSvg />
                            </div>
                          );
                        } else if (item.hintCount === 3) {
                          return (
                            <div className="givenHints">
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg className={shake ? `shake` : null} />
                              <InactiveSvg />
                              <InactiveSvg />
                            </div>
                          );
                        } else if (item.hintCount === 4) {
                          return (
                            <div className="givenHints">
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg className={shake ? `shake` : null} />
                              <InactiveSvg />
                            </div>
                          );
                        } else if (item.hintCount === 5) {
                          return (
                            <div className="givenHints">
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg className={shake ? `shake` : null} />
                            </div>
                          );
                        } else if (item.hintCount > 5) {
                          return (
                            <div className="givenHints">
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg />
                              <ActiveSvg />
                              <div className="aditionalHints">
                                + {`${item.hintCount - 5}`}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="givenHints">
                              <InactiveSvg />
                              <InactiveSvg />
                              <InactiveSvg />
                              <InactiveSvg />
                              <InactiveSvg />
                            </div>
                          );
                        }
                      })()}

                      <div className="timeLeft">
                        <div className="time">13:55</div>
                      </div>
                      <div className="points">
                        {Math.round(`${item.points}`)}
                      </div>

                      <div className="progresses">
                        <progress
                          id="file"
                          value={item.progress}
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                );
              })}
          ,
          {/* {allTeams.slice(1).map((item, index) => {
            return (
              <div className="specificTeam" key={item.id} id={item.id}>
                <div className="starginPlayer">
                  <span>{index + 2}</span>
                  <img
                    src={`https://arena-api.qwertify.io${item.image}`}
                    alt={`https://arena-api.qwertify.io${item.image}`}
                  />
                </div>
                <div className="wraperProgress">
                  <div className="teamName">{item.name}</div>
                  <div className="companyAndRoom">
                    <h5>{item.companyName}</h5>
                    <h6>{item.roomName}</h6>
                  </div>
                  <div className="teamCity">
                    <h6> {!item.roomLocation ? "-" : item.roomLocation}</h6>
                  </div>
                  {(() => {
                    if (item.hintCount === 1) {
                      return (
                        <div className="givenHints">
                          <ActiveSvg className={shake ? `shake` : null} />
                          <InactiveSvg />
                          <InactiveSvg />
                          <InactiveSvg />
                          <InactiveSvg />
                        </div>
                      );
                    } else if (item.hintCount === 2) {
                      return (
                        <div className="givenHints">
                          <ActiveSvg />
                          <ActiveSvg className={shake ? `shake` : null} />
                          <InactiveSvg />
                          <InactiveSvg />
                          <InactiveSvg />
                        </div>
                      );
                    } else if (item.hintCount === 3) {
                      return (
                        <div className="givenHints">
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg className={shake ? `shake` : null} />
                          <InactiveSvg />
                          <InactiveSvg />
                        </div>
                      );
                    } else if (item.hintCount === 4) {
                      return (
                        <div className="givenHints">
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg className={shake ? `shake` : null} />
                          <InactiveSvg />
                        </div>
                      );
                    } else if (item.hintCount === 5) {
                      return (
                        <div className="givenHints">
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg className={shake ? `shake` : null} />
                        </div>
                      );
                    } else if (item.hintCount > 5) {
                      return (
                        <div className="givenHints">
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg />
                          <ActiveSvg />
                          <div className="aditionalHints">
                            + {`${item.hintCount - 5}`}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="givenHints">
                          <InactiveSvg />
                          <InactiveSvg />
                          <InactiveSvg />
                          <InactiveSvg />
                          <InactiveSvg />
                        </div>
                      );
                    }
                  })()}

                  <div className="timeLeft">
                    <div className="time">13:55</div>
                  </div>
                  <div className="points">{Math.round(`${item.points}`)}</div>

                  <div className="progresses">
                    <progress
                      id="file"
                      value={item.progress}
                      max="100"
                    ></progress>
                  </div>
                </div>
              </div>
            );
          })} */}
        </div>

        {/* Top players */}
        {trueObjects.length === 1 ? (
          <div className="topPlayers">
            <div className="leftSide">
              {bestTimes.slice(0, 5).map((item, index) => {
                return (
                  <div className="topPlayerCard" key={item.id}>
                    <div className="teamRate">{index + 1}</div>
                    <div className="teamName">{item.name}</div>
                    <div className="teamScore">
                      <span>{item.points} POINTS</span> &nbsp; /{" "}
                      {item.hintCount} HINTS / {item.timeLeft.slice(0, 2)}m{" "}
                      {item.timeLeft.slice(3, 5)}s LEFT
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="rightSide">
              {bestTimes.slice(5, 10).map((item, index) => {
                return (
                  <div className="topPlayerCard" key={item.id}>
                    <div className="teamRate">{index + 6}</div>
                    <div className="teamName">{item.name}</div>
                    <div className="teamScore">
                      <span>{item.points} POINTS</span> &nbsp; /{" "}
                      {item.hintCount} HINTS / {item.timeLeft.slice(0, 2)}m{" "}
                      {item.timeLeft.slice(3, 5)}s LEFT
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* End top players */}
      </div>
    </div>
  );
};

export default InGame;
