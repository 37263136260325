import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { API } from "../../services/base";
import axios from "../../services/https";

import "../../style/gameMaster/CheckScreen.scss";

import { TailSpin } from "react-loader-spinner";

import { useDispatch } from "react-redux";
import { saveRoom } from "../../redux/actions/app.js";

const CheckScreen = () => {
  let [token, setToken] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  token = window.location.href.split("/").pop();

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);
  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        navigate("/welcome-screen");
        dispatch(saveRoom(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        switch (err.response.status) {
          case 403:
            navigate("/token-error");
          default:
            break;
        }
      });
  });

  return (
    <div className="checkScreen">
      <TailSpin
        height="80"
        width="80"
        color="#808080"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default CheckScreen;
