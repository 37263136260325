import React, { useState, useEffect } from "react";
import Button from "../../elements/Button.js";

import "../../style/gameMaster/WelcomeScreen.scss";
import LogoPosition from "../../components/LogoPosition.js";
import { useNavigate } from "react-router-dom";

// import { API } from "../../services/base";
// import axios from "../../services/https";

const WelcomeScreen = (props) => {
  // let [token, setToken] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   localStorage.setItem("token", token);
  // }, [token]);

  //  useEffect(() => {
  //    axios
  //      .get(`${API}/room`)
  //      .then((res) => {})
  //      .catch((err) => {
  //        console.log(err);
  //        switch (err.response.status) {
  //          case 403:
  //            navigate("/token-error");
  //          default:
  //            break;
  //        }
  //      });
  //  });

  const handleChildClick = () => {
    navigate("/team");
  };

  return (
    <div className="welcomeScreen">
      <div className="content">
        <LogoPosition />

        <h2>Welcome to ARENA!</h2>
        <p>
          Hello Game Master, and welcome to ARENA! Click on the button bellow to
          add your team in an online match!
        </p>

        <Button title="Create new team" onClick={handleChildClick} />
      </div>
    </div>
  );
};

export default WelcomeScreen;
