import React, { useState } from "react";
import "../../style/gameMaster/Congratulations.scss";

import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button.js";

import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate } from "react-router-dom";

const Congratulations = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handleResetRoom = () => {
    axios
      .post(`${API}/room/reset`)
      .then((res) => {
        navigate(`/check/${token}`);
        // navigate("/available-matches");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="congratulations">
      <LogoPosition />
      <div className="content">
        <h2>Congratulations!</h2>
        <p>
          Your team has successfully completed their escape room! All that’s
          left is to wait for the remaining teams to finish! Thank you for being
          patient!
        </p>
        <Button title="Reset your room" onClick={handleResetRoom} />
      </div>
    </div>
  );
};

export default Congratulations;
