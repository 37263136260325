import React, { useEffect, useState } from "react";
import "../../style/gameMaster/ListingPage.scss";
import hint from "../../images/hint-inactive.svg";
import hintActive from "../../images/hint-active-red.svg";
import team from "../../images/team.jpg";
import undoIcon from "../../images/undo-icon.svg";

import { useTransition, animated } from "react-spring";
import Button from "../../elements/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API } from "../../services/base";
import axios from "../../services/https";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getResetRoom } from "../../redux/actions/app.js";
import moment from "moment";

const MyPlatform = () => {
  const [complete, setComplete] = useState(false);
  const [giveHint, setGiveHint] = useState(false);
  const [resetRoom, setResetRoom] = useState(false);
  const [undoStep, setUndoStep] = useState(false);
  let [hintCount, setHintCount] = useState(0);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [room, setRoom] = useState(localStorage.getItem("room"));

  const [stepUndo, setStepUndo] = useState("");

  let [newHints, setNewHints] = useState(0);

  let [currentTime, setCurrentTime] = useState("");

  const [listingDetails, setListingDetails] = useState("");
  const [steps, setSteps] = useState([]);

  const [expanded, setExpanded] = useState("panel1");

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //   Complete
  const transitionsComplete = useTransition(complete, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const completeTransitions = useTransition(complete, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });
  //   End complete

  //   Give hint
  const transitionsHint = useTransition(giveHint, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const hintTransitions = useTransition(giveHint, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });
  //   End give hint

  // Undo step
  const transitionsUndo = useTransition(undoStep, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const undoTransitions = useTransition(undoStep, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });
  // End undo step

  //   Reset room
  const transitionsReset = useTransition(resetRoom, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const resetTransitions = useTransition(resetRoom, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });
  //   End reset room

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setListingDetails(res.data.data);
        setSteps(res.data.data.steps);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCompleteRoom = () => {
    const data = {
      game: app.lobby.id,
    };
    axios
      .post(`${API}/game/${app.lobby.id}/finish`, data)
      .then((res) => {
        const completeTeam = res.data.data.teams.find((obj) => {
          return obj.id === app.team.id;
        });
        navigate("/congratulations");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(app.time, "STARTED TIME");
  // console.log(moment().format("HH:mm:ss"), "CURRENT TIME");

  useEffect(() => {
    const interval = setInterval(() => {
      var myzone = moment.tz.guess();
      let start = moment.utc(app.time).tz(myzone);
      currentTime = moment();
      let diff = currentTime.diff(start);

      currentTime = moment.utc(diff).format("mm:ss");

      setCurrentTime(moment.utc(diff).format("mm:ss"));
    }, 600);

    return () => clearInterval(interval);
  }, [currentTime]);

  // console.log(currentTime);

  const handleGiveHint = () => {
    const data = {
      game: app.lobby.id,
    };
    axios
      .post(`${API}/game/${app.lobby.id}/hint`, data)
      .then((res) => {
        setGiveHint(!giveHint);
        setHintCount(hintCount + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleResetRoom = () => {
    const data = {
      game: app.lobby.id,
    };

    if (app.get.length > 1) {
      axios
        .post(`${API}/game/${app.lobby.id}/finish`, data)
        .then((res) => {
          navigate(`/check/${token}`);
          setTimeout(() => {
            axios
              .post(`${API}/room/reset`)
              .then((res) => {
                navigate(`/check/${token}`);
              })
              .catch((err) => {
                console.log(err);
              });
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${API}/room/reset`)
        .then((res) => {
          navigate(`/check/${token}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // const handleResetRoom = () => {
  //   axios
  //     .post(`${API}/room/reset`)
  //     .then((res) => {
  //       navigate(`/check/${token}`);
  //       // navigate("/available-matches");
  //       // dispatch(getResetRoom(app.team));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleCheckBox = (event, index, id) => {
    const checked = event.target.checked;

    setSteps((steps) => {
      return [
        ...steps.slice(0, index),

        { ...steps[index], checked },

        ...steps.slice(index + 1),
      ];
    });

    const data = {
      game: app.lobby.id,
      step: id,
    };

    axios
      .post(`${API}/game/${app.lobby.id}/step/${id}`, data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUndoStepPopup = () => {
    const data = {
      game: app.lobby.id,
      step: stepUndo,
    };

    axios
      .delete(`${API}/game/${app.lobby.id}/step/${stepUndo}`, data)
      .then((res) => {
        setUndoStep(!undoStep);
        const element = document.getElementById(stepUndo);
        element.style.display = "none";

        const text = document.getElementById("step" + stepUndo);
        text.style.color = "#fff";

        // var x = document.getElementsByClassName("checkbox");
        // var i;
        // for (i = 0; i <= x.length; i++) {
        //   x[i].checked = false;
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUndo = (id) => {
    setStepUndo(id);
    setUndoStep(!undoStep);
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className="listingPage">
      {/* Leave match */}
      {transitionsComplete(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setComplete(false)}
            ></animated.div>
          )
      )}

      {completeTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                <h2 className="leaveMatchTitle">
                  Are you sure you want to complete room?
                </h2>
                <Button
                  title="COMPLETE ROOM"
                  className="stayBtn"
                  onClick={handleCompleteRoom}
                />
                <Link className="leaveMatch" onClick={() => setComplete(false)}>
                  go back
                </Link>
              </div>
            </animated.div>
          )
      )}
      {/* End leave match */}

      {/* Give hint */}
      {transitionsHint(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setGiveHint(false)}
            ></animated.div>
          )
      )}

      {hintTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                <h2 className="leaveMatchTitle">
                  Are you sure you want to give hint to your team?
                </h2>
                <Button
                  title="GIVE HINT TO TEAM"
                  className="stayBtn"
                  onClick={handleGiveHint}
                />
                <Link className="leaveMatch" onClick={() => setGiveHint(false)}>
                  go back
                </Link>
              </div>
            </animated.div>
          )
      )}
      {/* End give hint */}

      {/* Undo */}
      {transitionsUndo(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setUndoStep(false)}
            ></animated.div>
          )
      )}

      {undoTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                <h2 className="leaveMatchTitle">
                  Are you sure you want to undo step?
                </h2>
                <Button
                  title="UNDO STEP"
                  className="stayBtn"
                  onClick={handleUndoStepPopup}
                />
                <Link className="leaveMatch" onClick={() => setUndoStep(false)}>
                  go back
                </Link>
              </div>
            </animated.div>
          )
      )}
      {/* End give undo */}

      {/* Reset room */}
      {transitionsReset(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setResetRoom(false)}
            ></animated.div>
          )
      )}

      {resetTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                <h2 className="leaveMatchTitle">
                  Are you sure you want to reset your escape room?
                </h2>
                <Button
                  title="RESET ROOM"
                  className="stayBtn"
                  onClick={handleResetRoom}
                />
                <Link
                  className="leaveMatch"
                  onClick={() => setResetRoom(false)}
                >
                  go back
                </Link>
              </div>
            </animated.div>
          )
      )}
      {/* End reset room */}

      <div className="header">
        <button className="completeRoom" onClick={() => setComplete(!complete)}>
          complete room
        </button>
      </div>

      <div className="content">
        <div className="timeSpent">
          <h6>Time spent</h6>
          <span className="timer">{currentTime}</span>
        </div>

        <div className="hints">
          <h6>Hints</h6>
          <div>
            {(() => {
              if (hintCount === 1) {
                return (
                  <div className="allHints">
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                  </div>
                );
              } else if (hintCount === 2) {
                return (
                  <div className="allHints">
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                  </div>
                );
              } else if (hintCount === 3) {
                return (
                  <div className="allHints">
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                  </div>
                );
              } else if (hintCount === 4) {
                return (
                  <div className="allHints">
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                  </div>
                );
              } else if (hintCount === 5) {
                return (
                  <div className="allHints">
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                  </div>
                );
              } else if (hintCount > 5) {
                return (
                  <div className="allHints">
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <img src={hintActive} alt="hint-inactive" />
                    <span>+ {hintCount - 5}</span>
                  </div>
                );
              } else {
                return (
                  <div className="allHints">
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                    <img src={hint} alt="hint-inactive" />
                  </div>
                );
              }
            })()}
          </div>

          <Button title="give hint" onClick={() => setGiveHint(!giveHint)} />
        </div>

        <div className="steps">
          <h6>Escape room steps</h6>

          <div className="allSteps">
            {steps.map((item, i) => {
              return (
                <div className="roomStep" key={item.id}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={(e) => handleCheckBox(e, i, item.id)}
                    value={item.checked}
                  />
                  <span
                    style={{
                      color: item.checked ? "#767676" : "#fff",
                    }}
                    id={`step${item.id}`}
                  >
                    {item.name}
                  </span>
                  <img
                    className="undoIcon"
                    style={{
                      display: item.checked ? "block" : "none",
                    }}
                    id={item.id}
                    src={undoIcon}
                    alt="undoIcon"
                    onClick={() => handleUndo(item.id, item.checked)}
                  />
                </div>
              );
            })}
          </div>
          <div></div>
        </div>

        <div className="teamCards">
          <div className="card">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="globalDropdown"
              >
                <Typography component={"span"} className="teamDropdown">
                  <span>Team name</span> {app.team.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordionDetails">
                <Typography component={"span"} className="teamImageAccordion">
                  <div className="teamImage">
                    <div className="image">
                      <img
                        src={`https://arena-api.qwertify.io${app.team.image}`}
                        alt="team"
                      />
                    </div>
                  </div>
                  <div className="teamInfo">
                    <div className="roomFranchize">
                      <span>Escpare room franchize</span>
                      <h5>{listingDetails.company?.name}</h5>
                    </div>

                    <div className="location">
                      <span>Location</span>
                      {!listingDetails.address ? (
                        <h5>-</h5>
                      ) : (
                        <h5>Los Angeles</h5>
                      )}
                    </div>

                    <div className="roomName">
                      <span>Escape room name</span>
                      <h5>{listingDetails.name}</h5>
                    </div>

                    <div className="difficultyLevel">
                      <span>Difficulty level</span>
                      <h5>{listingDetails.difficultyLevel}</h5>
                    </div>

                    <div className="solvingFrame">
                      <span>Time frame for solving</span>
                      <h5>around {listingDetails.duration} minutes</h5>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="resetRoom">
          <button className="resetBtn" onClick={() => setResetRoom(!resetRoom)}>
            RESET YOUR ESCAPE ROOM
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyPlatform;
