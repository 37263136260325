import React from "react";
import "../../style/gameMaster/TokenErrorPage.scss";

const TokenErrorPage = () => {
  return (
    <div className="tokenError">
      <h2>Permission denied</h2>
      <p>You don't have permission to go to another page</p>
    </div>
  );
};

export default TokenErrorPage;
