import React, { useState, useEffect, useMemo } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Echo from "laravel-echo";
import { useTransition, animated } from "react-spring";
import { API } from "../../services/base";
import axios from "../../services/https";

import { useDispatch } from "react-redux";
import {
  availablesTeams,
  saveMyMatchLobby,
  startTime,
  saveTeamsDate,
} from "../../redux/actions/app.js";
import { useSelector } from "react-redux";

import Pagination from "../../components/Pagination";

import "../../style/gameMaster/AvailableMatches.scss";

import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button";
import Icon_SeeMore from "../../images/icons/Icon_SeeMore.svg";
import RemoveText from "../../images/close-icon.svg";
import hexagon from "../../images/red.svg";

import moment from "moment";
import "moment-timezone";

const AvailableMatches = () => {
  const [notification, setNotification] = useState("");
  const [cards, setCards] = useState(localStorage.getItem("token"));
  const [time, setTime] = useState(localStorage.getItem("time"));
  const app = useSelector((state) => state.app);

  const [noMath, showMatch] = useState([]);
  const [ot, setOt] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(3);

  // Search
  const [deleteSearch, setDeleteSearch] = useState(false);
  const [query, setQuery] = useState("");

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  var myzone = moment.tz.guess();
  // var localTime = moment.utc(`${time}`, "HH:mm").tz(myzone).format("HH:mm");
  var localTime = moment
    .utc(`${time}`, "HH:mm")
    .utcOffset(myzone)
    .format("HH:mm");

  // console.log(moment.utc(`${time}`, "HH:mm").utcOffset(myzone).format("HH:mm"));

  useEffect(() => {
    axios
      .get(`${API}/game?startTime=${localTime}`)
      .then((res) => {
        showMatch(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = noMath.slice(firstPostIndex, lastPostIndex);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const matchTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleNewMatch = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        dispatch(saveMyMatchLobby(res.data.data));
        navigate("/my-match-lobby");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSolo = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        localStorage.setItem("soloGameId", res.data.data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSoloMatch = () => {
    setOpen(!open);
  };

  const handleLink = (teams, id) => {
    dispatch(availablesTeams(teams));
    localStorage.setItem("gameId", id);
  };

  const handleProceed = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        dispatch(saveMyMatchLobby(res.data.data));
        navigate("/my-match-lobby");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleProceed = () => {
  //   const data = {
  //     teamId: app.team.id,
  //     startTime: localTime,
  //   };
  //   axios
  //     .post(`${API}/game`, data)
  //     .then((res) => {
  //       axios
  //         .post(`${API}/game/${res.data.data.id}/start`)
  //         .then((res) => {
  //           navigate("/solo-platform");
  //           localStorage.setItem("solo", res.data.data.id);
  //           localStorage.setItem("soloTrue", 0);

  //           const found = res.data.data.teams.find((obj) => {
  //             return obj.id === app.team.id;
  //           });

  //           dispatch(startTime(found.gameStartedAt));
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div className="availableMatches">
      {/* Solo match */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {matchTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                <h2>Are you sure you want to go Solo?</h2>
                <p>
                  Once you switch to Solo game, you will not be able to compete
                  with other Escape rooms.
                </p>
                <Button
                  title="Proceed"
                  className="stayBtn"
                  onClick={handleProceed}
                />
                <Link className="goBack" onClick={() => setOpen(false)}>
                  go back to lobby
                </Link>
              </div>
            </animated.div>
          )
      )}
      {/* End solo match */}

      <div className="bar">
        <LogoPosition />
        <div className="search-container">
          <div id="search-wrap">
            <form role="search" method="get" action="">
              <input
                type="text"
                id="search-text"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
              {/* {query != "" ? (
                <img src={RemoveText} alt="deleteText" onClick={deleteText} />
              ) : (
                ""
              )} */}
            </form>
          </div>
        </div>
      </div>

      {noMath.length > 0 ? (
        <div className="availableMatchesCards">
          <h2>Available matches</h2>
          <Button title="CREATE NEW MATCH" onClick={handleNewMatch} />

          <div className="matchCardContent">
            {query === "" ? (
              <div>
                {currentPosts.map((item, index) => {
                  return (
                    <div className="matchCard" key={item.id}>
                      <div className="header">
                        <div className="cardNumber">
                          {/* <img src={hexagon} /> */}
                          <h6>{item.id}</h6>
                        </div>
                        <div className="numberOfTeams">
                          <h6>Match admin info</h6>
                        </div>
                      </div>
                      <div className="content">
                        <div className="roomFranchize">
                          <span>Escape room franchize</span>
                          <h5>{item.company}</h5>
                        </div>

                        <div className="location">
                          <span>Location</span>
                          {item.location ? (
                            <h5>{item.location}</h5>
                          ) : (
                            <h5>-</h5>
                          )}
                        </div>

                        <div className="roomName">
                          <span>Escape room name</span>
                          <h5>{item.room}</h5>
                        </div>

                        <div className="location">
                          <span>Escape room adress</span>
                          {item.location ? (
                            <h5>{item.location}</h5>
                          ) : (
                            <h5>-</h5>
                          )}
                        </div>
                      </div>
                      <div className="seeMore">
                        <Link
                          to={`/join-match/${item.id}`}
                          onClick={() => handleLink(item.teams, item.id)}
                        >
                          See more
                          {/* <img src={Icon_SeeMore} alt="Icon_SeeMore" /> */}
                        </Link>
                      </div>
                    </div>
                  );
                })}
                {/* Pagination */}
                <Pagination
                  totalPosts={noMath.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />

                {/* End pagination */}
              </div>
            ) : (
              noMath
                .filter((noMath) =>
                  noMath.company.toLowerCase().includes(query)
                )
                .map((item, index) => {
                  return (
                    <div className="matchCard" key={item.id}>
                      {/* <div className="header">
                        <div className="cardNumber">
                          {item.id}
                          <div className="hexagon">
                            <div className="hex-wrapper">
                              <div className="hex-border"></div>
                            </div>
                          </div>
                        </div>
                        <div className="numberOfTeams">
                          Number of teams in match{" "}
                          <span>{item.teams.length} / 5</span>
                        </div>
                      </div> */}
                      <div className="content">
                        <h6>{item.id} &nbsp; Match admin info</h6>

                        <div className="roomFranchize">
                          <span>Escape room franchize</span>
                          <h5>{item.company}</h5>
                        </div>

                        <div className="location">
                          <span>Location</span>
                          {item.location ? (
                            <h5>{item.location}</h5>
                          ) : (
                            <h5>-</h5>
                          )}
                        </div>

                        <div className="roomName">
                          <span>Escape room name</span>
                          <h5>{item.room}</h5>
                        </div>
                      </div>
                      <div className="seeMore">
                        <Link
                          to={`/join-match/${item.id}`}
                          onClick={() => handleLink(item.teams, item.id)}
                        >
                          See more
                          {/* <img src={Icon_SeeMore} alt="Icon_SeeMore" /> */}
                        </Link>
                      </div>
                    </div>
                  );
                })
            )}
          </div>

          <Button title="CREATE NEW MATCH" onClick={handleNewMatch} />
        </div>
      ) : (
        <div className="noAvailableMatches">
          <h2>Available matches</h2>
          <p>Unfortunately, there are currently no available matches.</p>
          <Button title="CREATE NEW MATCH" onClick={handleNewMatch} />
          <button className="soloMatch" onClick={handleSoloMatch}>
            START SOLO MATCH
          </button>
        </div>
      )}
    </div>
  );
};

export default AvailableMatches;
