import axios from "axios";
import { getToken } from "../utils/Common";
import { API } from "./base";
import { Navigate } from "react-router-dom";

axios.defaults.baseURL = API;

axios.interceptors.request.use(async (instance) => {
  const token = await getToken("token");
  if (token) {
    // eslint-disable-next-line no-param-reassign
    instance.headers.Authorization = `Bearer ${token}`;
  }

  return instance;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      <Navigate to="/team" />;
    }
    return Promise.reject(error);
  }
);

export default axios;
