import React, { useState, useEffect } from "react";
import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate, useParams } from "react-router-dom";

import "../../style/gameMaster/JoinMatch.scss";
import LogoPosition from "../../components/LogoPosition";
import { Link } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import arrow from "../../images/icons/Icon_Arrow.svg";
import team from "../../images/team.jpg";
import Button from "../../elements/Button";

import { useDispatch } from "react-redux";
import { saveTeam } from "../../redux/actions/app.js";
import { useSelector } from "react-redux";
import AvailableMatches from "./AvailableMatches";

import Echo from "laravel-echo";

const JoinMatch = () => {
  const [expanded, setExpanded] = useState(false);
  const [game, setGame] = useState(1);
  const { id } = useParams();
  const [matchId, setMatchiTD] = useState(localStorage.getItem("gameId"));

  // const [time, setTime] = useState(localStorage.getItem("time"));

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleJoinMatch = () => {
    // navigate("/match-lobby");

    const data = {
      teamId: app.team.id,
      game: matchId,
    };

    axios
      .post(`${API}/game/${matchId}/join`, data)
      .then((res) => {
        navigate("/match-lobby");
        window.scrollTo(0, 0);
        console.log(res.data.data);
        // dispatch(saveTeam(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(app);

  return (
    <div className="joinMatch">
      <LogoPosition />

      <Link className="goBack" to="/available-matches">
        <img src={arrow} /> Go back
      </Link>
      <h2>Match lobby </h2>
      <Button
        title="Join this match"
        className="joinMatchBtn"
        onClick={handleJoinMatch}
      />

      <div className="teamCards">
        <div className="card">
          {app.availableteams.map((item, index) => {
            return (
              <Accordion
                key={item.id}
                expanded={expanded === item.id}
                onChange={handleChange(item.id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="globalDropdown"
                >
                  <Typography component={"span"} className="teamDropdown">
                    <span>Team name</span> {item.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordionDetails">
                  <Typography component={"span"} className="teamImageAccordion">
                    <div className="teamImage">
                      <div className="image">
                        <img
                          src={`https://arena-api.qwertify.io${item.image}`}
                          alt="team"
                        />
                      </div>
                    </div>
                    <div className="teamInfo">
                      <div className="roomFranchize">
                        <span>Escpare room franchize</span>
                        <h5>{item.roomName}</h5>
                      </div>

                      <div className="location">
                        <span>Location</span>
                        {!item.roomAddress ? (
                          <h5>-</h5>
                        ) : (
                          <h5>{item.roomAddress}</h5>
                        )}
                      </div>

                      <div className="roomName">
                        <span>Escape room name</span>
                        <h5>Tesla’s Electric Mystery</h5>
                      </div>

                      <div className="difficultyLevel">
                        <span>Difficulty level</span>
                        <h5>{item.roomDifficultyLevel}</h5>
                      </div>

                      <div className="solvingFrame">
                        <span>Time frame for solving</span>
                        <h5>around {item.roomDuration} minutes</h5>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>

      <Button
        title="Join this match"
        className="joinMatchBtn"
        onClick={handleJoinMatch}
      />
      <Link className="goBackDownPage" to="/available-matches">
        <img src={arrow} /> Go back
      </Link>
    </div>
  );
};

export default JoinMatch;
