import React, { useState, useEffect } from "react";
import "../../style/gameMaster/Team.scss";

import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button";

import { useTransition, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/base";
import axios from "../../services/https";

import { useDispatch } from "react-redux";
import { saveTeam } from "../../redux/actions/app.js";

import { TailSpin } from "react-loader-spinner";

import moment from "moment";
import "moment-timezone";

const Team = () => {
  const [name, setName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [source, setSource] = useState("");
  const [imagePreview, setImage] = useState(null);

  const [preparingSubmit, setPreparingSubmit] = useState(false);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   localStorage.setItem("time", localTime);
  // }, [localTime]);

  var myzone = moment.tz.guess();
  // var localTime = moment
  //   .utc(`${startTime}`, "HH:mm")
  //   // .tz(myzone)
  //   .format("HH:mm");

  const strDate = startTime;
  const arr = strDate.split(":");
  const splitHour = parseInt(arr[0]);
  const splitMin = parseInt(arr[1]);

  // console.log(hour, "sati");
  // console.log(min, "minuti");

  var zoneTime = moment();
  var parsedHourFromUser = splitHour;
  var parsedMinutesFromUser = splitMin;
  zoneTime.hour(parsedHourFromUser);
  zoneTime.minute(parsedMinutesFromUser);

  const myTime = zoneTime.utc().format("HH:mm");

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const popupTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleImage = (e) => {
    setSource(e.target.files[0]);

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImage = () => {
    setImage(null);
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      image: source,
    };

    axios
      .post(`${API}/team`, data, config)
      .then((res) => {
        // setOpen(false);
        navigate("/available-matches");
        localStorage.setItem("time", myTime);
        // navigate("/available-matches");

        // localStorage.setItem("user", JSON.stringify(res.data.data));
        dispatch(saveTeam(res.data.data));
      })
      .catch((err) => {
        if (open === err) {
          setOpen(false);
        }
        setError(err.response.data.errors);
        setPreparingSubmit(false);
        window.scrollTo(0, 20);
      });
  };

  const handleOpenPopup = (e) => {
    e.preventDefault();

    setOpen(!open);
    window.scrollTo(0, 20);
  };

  return (
    <div className="team">
      <LogoPosition />

      {/* Team info popup */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {popupTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="popUp">
              <form className="popupHeader" onSubmit={handleSubmit}>
                <h2>Save team information?</h2>
                <p>Once you save this information, it can’t be edited.</p>

                <div className="info">
                  <label>Team name</label>
                  <h2 className="popupInfo">{name}</h2>
                  {error ? <p className="errorMessage">{error.name}</p> : ""}
                </div>

                <div className="info">
                  <label>Start time</label>
                  <h2 className="popupInfo">{startTime}</h2>
                </div>

                <div className="info">
                  <label>Team photo</label>
                  <img src={imagePreview} alt="preview image" />
                  {error ? <p className="errorMessage">{error.image}</p> : ""}
                </div>

                <Button
                  title="SAVE INFORMATION"
                  type="submit"
                  onClick={() => setPreparingSubmit(true)}
                />

                {preparingSubmit ? (
                  <TailSpin
                    height="80"
                    width="80"
                    color="#808080"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="teamSpiner"
                    visible={true}
                  />
                ) : (
                  ""
                )}

                <button className="goBack" onClick={() => setOpen(false)}>
                  go back
                </button>
              </form>
            </animated.div>
          )
      )}
      {/* End team info popup */}

      <h2>Create new team</h2>
      <p>Enter team name and insert number of players if needed</p>

      <form onSubmit={handleOpenPopup}>
        <div className="element">
          <label>Team name</label>
          <input
            type="text"
            placeholder="Enter team name here..."
            maxLength="15"
            onChange={(e) => setName(e.target.value)}
          />
          {error ? <p className="errorMessage">{error.name}</p> : ""}
        </div>
        <div className="element">
          <label>Start time</label>
          <input
            className="time"
            type="time"
            onChange={(e) => setStartTime(e.target.value)}
            placeholder="19:00"
          />
        </div>
        <div className="element">
          <label>Team photo</label>
          {imagePreview ? (
            <div className="previewImage">
              <img src={imagePreview} alt="preview image" />
              <button onClick={removeImage}>Remove image</button>
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="fileField" className="attachment">
                <div className="row btn-file">
                  <div className="btn-file__preview"></div>
                  <div className="btn-file__actions">
                    <div className="btn-file__actions__item col-xs-12 text-center">
                      <div className="btn-file__actions__item--shadow">
                        Click on Take photo to take a picture of your team. We
                        recommend taking the photo of your team in horizontal
                        <p>Take a photo</p>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  name="myImage"
                  type="file"
                  id="fileField"
                  accept="image/*"
                  capture="camera"
                  onChange={handleImage}
                />
              </label>
              {error ? <p className="errorMessage">{error.image}</p> : ""}
            </div>
          )}
        </div>
        <Button title="Save team information" />
      </form>
    </div>
  );
};

export default Team;
