import React, { useState, useEffect, useRef } from "react";
import Echo from "laravel-echo";
import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate } from "react-router-dom";

// Style
import "../../style/gameMaster/New.scss";
import hintActive from "../../images/hint-active-red.svg";

// Images
import team from "../../images/team.jpg";
import hint from "../../images/hint-inactive.svg";

import moment from "moment";

// Redux
import { connect, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getTeams,
  member,
  startedGame,
  showGameId,
  completedSteps,
  givenHint,
  roomReset,
  finish,
} from "../../redux/actions/app.js";

const New = () => {
  const [roomChannel, setRoomChannel] = useState("");
  const navigate = useNavigate();

  //   All about token
  const [cards, setCards] = useState(localStorage.getItem("token"));
  const [soloCheck, setSoloCheck] = useState(localStorage.getItem("soloTrue"));
  let [token, setToken] = useState([]);
  token = window.location.href.split("/").pop();
  const [socketCh, setSocketCh] = useState("");
  const [gameId, setGameId] = useState("");

  const [allTeams, setAllTeams] = useState([]);
  const [bestTimes, setBestTimes] = useState([]);
  const [savedTeamsOnRefresh, setSavedTeamsOnRefresh] = useState([]);
  let [currentTime, setCurrentTime] = useState("");

  let [checkTime, setCheckTime] = useState("");
  //   Redux
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  // Timer

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [pause, setPause] = useState(false);

  // Btn animate

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 2000);
  };

  // End timer

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setRoomChannel(res.data.data.roomScreenChannel);
      })
      .catch((err) => {
        console.log(err);
        switch (err.response.status) {
          case 403:
            navigate("/");
          default:
            break;
        }
      }, []);

    localStorage.setItem("token", token);
  }, []);

  useEffect(() => {
    setAllTeams(app.get);
  }, [app]);

  useEffect(() => {
    if (app.gameid != null) {
      axios
        .get(`${API}/game/${app.gameid}/winner`)
        .then((res) => {
          setBestTimes(res.data.data.bestTimes);
        })
        .catch();
    }
  }, [app.gameid]);

  //   Socket

  useEffect(() => {
    window.Pusher = require("pusher-js");

    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "2UhByxffTQ7XjcYWgfHbumDj",
      cluster: "mt1",
      authEndpoint: "https://arena-api.qwertify.io/broadcasting/auth",
      wsHost: "arena-api.qwertify.io",
      auth: {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + `${cards}`,
        },
      },
      wsPort: 6001,
      wssPort: 6001,
      disableStats: true,
      encrypted: true,
      enabledTransports: ["ws", "wss"],
      transports: ["websocket"],
    });

    // channel.private-game-1 = roomScreenChannel
    window.Echo.channel(`${roomChannel}`)
      .listen(".redraw", (e) => {
        console.log("subscribed", e);

        localStorage.setItem("roomChannel", roomChannel);
        dispatch(showGameId(e.gameId));
        setSocketCh(e.socketChannel);
        setGameId(e.gameId);

        axios
          .get(`${API}/game/${e.gameId}`)
          .then((res) => {
            const gameid = e.gameId;
            setAllTeams(res.data.data.teams);
            dispatch(getTeams(res.data.data.teams));
            // setAllTeams(res.data.data.teams);

            window.Echo.channel(res.data.data.socketChannel)
              .listen(".game.game-started", (e) => {
                dispatch(startedGame(e.teamId));
              })
              .listen(".game.hint-given", (e) => {
                dispatch(givenHint(e));
                setShake(!shake);
              })
              .listen(".game.step-changed", (e) => {
                dispatch(completedSteps(e));
              })
              .listen(".game.team-joined", (e) => {
                dispatch(member(e.team));
              })
              .listen(".game.team-finished", (e) => {})
              .listen(".game.game-finished", (e) => {
                dispatch(finish(e));
                navigate("/winner-display");
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .listen(".reset", (e) => {
        navigate(`/new/${cards}`);
        localStorage.removeItem("persist:root");
        window.location.reload(false);
        console.log("RESET ROOM IN GAME");
      });

    window.Echo.connector.pusher.connection.bind(
      "state_change",
      function (states) {
        if (states.current === "disconnected") {
          window.Echo.connector.pusher.connect();
          window.Echo.connector.pusher.subscribe();
        }
      }
    );
  });

  useEffect(() => {
    if (app.gameid) {
      axios
        .get(`${API}/game/${app.gameid}`)
        .then((res) => {
          dispatch(getTeams(res.data.data.teams));
          setAllTeams(app.get);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // TIMER

  const [endDate, setEndDate] = useState(null);

  // console.log("endDate outside", endDate);
  var end = endDate;

  //  TIMER
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setAllTeams((previousState) =>
  //       previousState.map((team, index) => {
  //         if (team?.gameStartedAt) {
  //           // console.log("ima start date");
  //           let tempDiff = moment(
  //             team.gameStartedAt,
  //             "YYYY-MM-DD HH:mm:ss"
  //           ).diff(moment());
  //           return {
  //             ...team,
  //             currentTime: moment.utc(tempDiff).format("mm:ss"),
  //           };
  //         }
  //         // console.log("nema start date");
  //         return team;
  //       })
  //     );
  //   }, 600);
  //   return () => clearInterval(interval);
  // }, [allTeams]);
  // END TIMER

  return (
    <div className="new">
      <div className="bar">
        <ul>
          <li>Team name</li>
          <li>Company and Room</li>
          <li>City</li>
          <li>Hints given</li>
          <li>Time left</li>
          <li>Progress</li>
        </ul>
      </div>

      {!allTeams
        ? ""
        : allTeams.map((item, index) => {
            return (
              <div className="teamCard" key={item.id} id={item.id}>
                <div className="timNum">{index + 1}</div>
                <div className="teamInfo">
                  <div className="info">
                    <div className="teamImg">
                      <img
                        src={`https://arena-api.qwertify.io${item.image}`}
                        alt={`https://arena-api.qwertify.io${item.image}`}
                      />
                    </div>
                    <div className="teamName">
                      <p>{item.name}</p>
                    </div>
                    <div className="companyRoom">
                      <h6>{item.companyName}</h6>
                      <h6>{item.roomName}</h6>
                    </div>
                    <div className="city">
                      {!item.roomLocation ? "-" : item.roomLocation}
                    </div>
                    <div className="hintsGiven">
                      {(() => {
                        if (item.hintCount === 1) {
                          return (
                            <div className="allHints">
                              <img
                                className={shake ? `shake` : null}
                                src={hintActive}
                                alt="hint-inactive"
                              />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                            </div>
                          );
                        } else if (item.hintCount === 2) {
                          return (
                            <div className="allHints">
                              <img src={hintActive} alt="hint-inactive" />
                              <img
                                className={shake ? `shake` : null}
                                src={hintActive}
                                alt="hint-inactive"
                              />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                            </div>
                          );
                        } else if (item.hintCount === 3) {
                          return (
                            <div className="allHints">
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img
                                className={shake ? `shake` : null}
                                src={hintActive}
                                alt="hint-inactive"
                              />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                            </div>
                          );
                        } else if (item.hintCount === 4) {
                          return (
                            <div className="allHints">
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img
                                className={shake ? `shake` : null}
                                src={hintActive}
                                alt="hint-inactive"
                              />
                              <img src={hint} alt="hint-inactive" />
                            </div>
                          );
                        } else if (item.hintCount === 5) {
                          return (
                            <div className="allHints">
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img
                                className={shake ? `shake` : null}
                                src={hintActive}
                                alt="hint-inactive"
                              />
                            </div>
                          );
                        } else if (item.hintCount > 5) {
                          return (
                            <div className="allHints">
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <img src={hintActive} alt="hint-inactive" />
                              <div className="aditionalHints">
                                + {`${item.hintCount - 5}`}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="allHints">
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                              <img src={hint} alt="hint-inactive" />
                            </div>
                          );
                        }
                      })()}

                      {/* {item.hintCount > 5 ? (
                        <p className="aditionalHints">
                          + {`${item.hintCount - 5}`}
                        </p>
                      ) : (
                        ""
                      )} */}
                      {/* <h6>{item.hintCount}</h6> */}
                    </div>

                    <div className="timeLeft">
                      {item.currentTime ? item.currentTime : item.roomDuration}
                    </div>

                    <div className="progressNum">{item.progress}%</div>
                  </div>
                  <div className="progresses">
                    <progress
                      id="file"
                      value={item.progress}
                      max="100"
                    ></progress>
                  </div>
                </div>
              </div>
            );
          })}

      {allTeams.length === 1 ? (
        <div className="topPlaces">
          <h3>Top 10 best scores</h3>
          <div className="topPlacesCards">
            <div>
              {bestTimes.slice(0, 5).map((item, index) => {
                return (
                  <div className="topPlace" key={item.id}>
                    <div className="teamId">{index + 1}</div>
                    <div className="teamName">{item.name}</div>
                    <div className="teamPoints">{item.points}</div>
                  </div>
                );
              })}
            </div>
            <div>
              {bestTimes.slice(5, 10).map((item, index) => {
                return (
                  <div className="topPlace" key={item.id}>
                    <div className="teamId">{index + 6}</div>
                    <div className="teamName">{item.name}</div>
                    <div className="teamPoints">{item.points}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default New;
